:root {
  --gap-main-wrapper: 4px;
  --banner-height-min-input: 400;
  --banner-height-max-input: 1500;
  --banner-height-min-output: 35;
  --banner-height-max-output: 50;
}

h1 {
  font-size: 26px;
  font-weight: normal;
}

h2 {
  font-size: 24px;
  align-self: flex-start;
  font-weight: normal;
  opacity: 90%;
}

h3 {
  font-size: 20px;
  align-self: flex-start;
  font-weight: normal;
  margin-bottom: 8px;
}

h4 {
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

h5 {
  font-size: 16px;
  font-weight: normal;
  font-style: italic;
}

p {
  font-size: 18px;
  opacity: 80%;
  max-width: 700px;
}

.design-wrapper {
  background: darkgrey;
  width: 100%;
  height: 100%;
}

.about-link {
  align-self: center;
  line-height: 100%;
}

.text-link:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.project-date {
  font-size: 20px;
  opacity: 80%;
  font-style: italic;
}

.top-bar-wrapper {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 4px 4px 4px 4px;
  gap: 8px;
}

.top-bar-title-wrap {
  display: flex;
  align-content: center;
  background: lightgray;
}

.thumbnail-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 10 0 300px;
}

.thumbnail-img-wrapper {
  width: 100%;
  background: lightgray;
  height: var(--homepage-thumbnail-height);
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .thumbnail-img-wrapper {
    height: 280px;
  }
}

.banner-wrapper {
  background: lightgray;
  max-height: 400px;
}

.poster-img-wrapper {
  width: 100%;
  height: 100%;
}

.page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: xx-large;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
}

.main-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: var(--app-max-width);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* gap: var(--gap-main-wrapper); */
  overflow-y: scroll;
}

/* .body-wrapper { */
/* } */

.details-wrapper {
  padding-left: 12px;
  padding-right: 12px;
  /* padding-bottom: 12px; */
  /* overflow: scroll; */
  /* background: lightblue; */
}

/* .footer-spacer { */
/* } */

.body-wrapper h1 {
  padding-top: 18px;
}

.body-wrapper p {
  padding-top: 8px;
}

.player-wrap {
  padding-top: 24px;
}

.youtube-player {
  border-radius: 8px;
}

.poster-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
