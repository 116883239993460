:root {
  --header-height: 56px;
  --footer-height: 48px;
  --gap-artworks: 4px;
  --gap-caption: 8px;
  --padding-h: 8px;
  --padding-left-caption: 12px;
  --break-point-2L: 960px;
  --break-point-2P: 860px;
  --break-point-3P: 950px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.photo-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: var(--gap-artworks);
  padding-top: var(--header-height);
  /* padding-bottom: var(--footer-height); */
  flex: 1 1 auto;
}
.media-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.photo-wrapper {
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.caption {
  padding-top: 18px;
  padding-bottom: 8px;
  padding-right: 12px;
  font-size: 18px;
  /* background: bisque; */
}

.imagesPlusCaption {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-column {
  flex-direction: column;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-center {
  justify-content: center;
}

.photo-plus-caption {
  display: flex;
  gap: 8px;
}

.photo-plus-caption-L {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-self: center;
}

.photo-wrapper img {
  align-self: flex-end;
}

.title {
  color: black;
  opacity: 90%;
  font-size: 18px;
}

.subtitle {
  color: black;
  opacity: 65%;
  font-size: 18px;
}

.target {
  cursor: pointer;
  border: none;
  background: none;
}

.page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
