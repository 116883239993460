:root {
  --app-max-width: 1750px;
  --homepage-thumbnail-height: 420px;
  --layout-breakpoint-homepage: 540px;
}

.app-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background: white;
}

.design-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.homepage-header {
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: start;
  padding: 0px 0px 8px 0px;
  flex-direction: row;
  gap: 9px;
  /* background: lightseagreen; */
}

.homepage-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: var(--app-max-width);
  padding: 20px 8px 0px 8px;
  /* background: lightblue; */
}

.homepage-wrapper main {
  /* flex: 2 0 auto; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* background: yellow; */
  overflow: scroll;
  padding-bottom: 70px;
  padding-top: 16px;
}

.social-bar-wrapper {
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  /* background: lightcoral; */
  background: rgba(255, 255, 255, 0.94);
}

.social-bar-content {
  width: 100%;
  /* flex: 2 0 auto; */
  display: flex;
  gap: 8px;
  flex-direction: row;
  /* background: lightgreen; */
  max-width: var(--app-max-width);
  padding: 12px 8px 8px 8px;
}

/* .social-bar-wrapper main { */
/* width: 100%; */
/* display: flex; */
/* flex-direction: row; */
/* background: lightskyblue; */
/* max-width: var(--app-max-width); */
/* padding: 12px 8px 8px 8px; */
/* } */

.eh-logo-wrapper {
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  background: lightgray;
  border-radius: 40px;
}

.eh-logotype-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}

.eh-title {
  flex: 0 0 auto;
  font-size: 30px;
  line-height: 100%;
  text-transform: uppercase;
  padding-top: 1px;
  opacity: 90%;
  letter-spacing: 0.2px;
}

.eh-subtitle {
  flex: 0 0 auto;
  font-size: 16px;
  line-height: 100%;
  opacity: 65%;
}

.media-list-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.about-link-wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  font-size: x-large;
}
