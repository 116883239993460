body {
  margin: 0;
  padding: 0;
  font-family: 'Crimson Text', serif;
  letter-spacing: -0.3px;
  color: rgb(30, 30, 30);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  user-select: none;
}

/* ::-webkit-scrollbar {
  display: none;
} */

ul {
  list-style-type: none;
}

img {
  display: block;
}

.trg {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: unset;
}
