/** Swiper styles **/

:root {
  --swiper-navigation-color: gray;
  --swiper-pagination-color: gray;
  --swiper-pagination-bullet-inactive-color: gray;
}

.swiper {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0px 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.swiper-slide-3f38 {
  background-color: rgba(51, 51, 51, 1);
}
.swiper-slide-content-cd26 {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 48px;
  gap: 0px;
}
.swiper-slide-text-79be {
  color: rgba(255, 255, 255, 1);
  text-align: left;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
}
.swiper-pagination {
  display: flex;
  justify-content: center;
  height: 56px;
  flex-wrap: wrap;
  align-content: center;
  gap: 3px;
}
