:root {
  --nav-button-size: 36px;
  --nav-button-side-margin: 8px;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  left: 0;
  bottom: 8px;
  width: var(--nav-button-size);
  height: var(--nav-button-size);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev.swiper-button-hidden,
.swiper-button-next.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.swiper-navigation-disabled .swiper-button-prev,
.swiper-navigation-disabled .swiper-button-next {
  display: none !important;
}
.swiper-button-prev svg,
.swiper-button-next svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
}
.swiper-rtl .swiper-button-prev svg,
.swiper-rtl .swiper-button-next svg {
  transform: rotate(180deg);
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--nav-button-side-margin, 48x);
  right: auto;
  /* background: aqua; */
  width: var(--footer-height);
  height: var(--footer-height);
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--nav-button-side-margin, 48x);
  left: auto;
  /* background: aqua; */
  width: var(--footer-height);
  height: var(--footer-height);
}
.swiper-button-lock {
  display: none;
}
/* Navigation font start */
.swiper-button-prev:after,
.swiper-button-next:after {
  background: url(../images/icon-chev-left.svg) left bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  font-size: xx-large;
  color: rgba(0, 0, 0, 0);
  background-size: 36px 36px;
  /* background-position: bottom left; */
}

/* Navigation font start */
.swiper-button-next:after,
.swiper-button-next:after {
  background: url(../images/icon-chev-right.svg) right bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: 36px 36px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--nav-button-side-margin, 48x);
  left: auto;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "";
}
/* Navigation font end */
